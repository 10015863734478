var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    height: '100%',
    '--themeColor': _vm.$store.state.themeInfo.themeColor,
  })},[(_vm.showHead)?_c('van-sticky',[_c('head-bar',{attrs:{"type":"primary","title":_vm.pageItem[_vm.pageKey] ? _vm.pageItem[_vm.pageKey].title : ''},on:{"onClickLeft":_vm.onClickLeft}})],1):_vm._e(),_c('van-skeleton',{staticClass:"mt-20",attrs:{"title":"","row":3,"loading":_vm.loading}}),(_vm.containsPage && !_vm.loading && _vm.conIt)?_c('div',{style:({
      height:
        _vm.pageItem[_vm.pageKey].type === 3 || _vm.pageItem[_vm.pageKey].type === 4
          ? 'calc(100% - 1.226rem)'
          : '100%',
    })},[(_vm.pageItem[_vm.pageKey].type === 5 && _vm.padInfo)?_c('reader',{attrs:{"padInfo":_vm.padInfo}}):_vm._e()],1):_vm._e(),(!_vm.containsPage)?_c('no-page'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }