<template>
  <audio
    ref="audio"
    src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202207261034477945516.mp3"
  ></audio>
</template>
<script>
import screenfull from "screenfull";
import { Toast } from "vant";
export default {
  data() {
    return {
      isFullScreen: false
    };
  },
  mounted() {
    screenfull.on("change", () => {
      this.isFullScreen = screenfull.isFullscreen;
      this.$emit("update:isFull", screenfull.isFullscreen);
    });
  },
  beforeDestroy() {
    screenfull.off("change");
  },
  methods: {
    play() {
      this.$refs.audio.play();
    },
    fullScreenToggle() {
      if (!screenfull.isEnabled) {
        Toast("您的浏览器不支持该功能！");
        return false;
      }
      const element = document.getElementById("fullscreen");
      screenfull.toggle(element);
    }
  }
};
</script>
<style style="less" scoped>
audio {
  display: none;
}
</style>
