<template>
  <div
    :style="{
      height: '100%',
      '--themeColor': $store.state.themeInfo.themeColor,
    }"
  >
    <van-sticky v-if="showHead">
      <head-bar
        type="primary"
        :title="pageItem[pageKey] ? pageItem[pageKey].title : ''"
        @onClickLeft="onClickLeft"
      />
    </van-sticky>
    <van-skeleton class="mt-20" title :row="3" :loading="loading" />
    <div
      v-if="containsPage && !loading && conIt"
      :style="{
        height:
          pageItem[pageKey].type === 3 || pageItem[pageKey].type === 4
            ? 'calc(100% - 1.226rem)'
            : '100%',
      }"
    >
   
      <reader
        v-if="pageItem[pageKey].type === 5 && padInfo"
        :padInfo="padInfo"
      />
    </div>
    <no-page v-if="!containsPage" />
  </div>
</template>
<script>
import noPage from "@/components/noPage/noPage";
import headBar from "@/components/headBar/headBar";
import { Dialog } from "vant";
import reader from "./components/reader.vue";
export default {
  name: "selfPage",
  components: { noPage, headBar, reader },
  data() {
    return {
      containsPage: false,
      loading: true,
      miceLink: "",
      pageItem: {},
      pageKey: "",
      conIt: false,
      videoUrl: {
        mt: 0,
        mr: 0,
        mb: 0,
        ml: 0,
      },
      formPath: "",
      padInfo: null,
      showHead: false,
    };
  },
  computed: {
    bgUrl() {
      return `url(${ this.pageItem[this.pageKey].videoCoverUrl }) no-repeat 0 0 /100% 100%`;
    },
    mt() {
      return this.videoUrl.mt + "px";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.formPath = from.path;
    });
  },
  mounted() {
    if (this.$route.params.linkname) {
      this.pageKey = this.$route.params.linkname;
      this.miceLink = this.$route.params.miceLink;
      this.containsPage = true;
      this.getContent();
    } else {
      this.containsPage = false;
    }
  },
  methods: {
    async getContent() {
      // 获取自建页面信息
      this.loading = this.containsPage = true;
      let result = await this.$http.get(
        this.$baseURL.index.getSelfPageContent() + "/" + this.pageKey,
        1
      );
      this.loading = false;
      if (result.code === 200) {
        this.showHead = true;
        if (result.data === null) {
          // 自建页面删除后处理
          this.containsPage = false;
          return;
        }
        const page = {};
        page.type = result.data.type;
        page.title = result.data.title;
        page.videoCoverUrl = result.data.videoCoverUrl
        if (page.type === 1) {
          page.content = this.$utils.handleLargerImg(
            /\w+\.(jpg|png|jpeg|gif|webp)/gi,
            "?imageView2/2/w/750",
            "",
            result.data.content
          );
          page.videoCoverUrl = result.data.videoCoverUrl;
          if (result.data.videoUrl) {
            const videoUrl = JSON.parse(result.data.videoUrl);
            this.videoUrl.mt = videoUrl.mt;
            this.videoUrl.mr = videoUrl.mr;
            this.videoUrl.mb = videoUrl.mb;
            this.videoUrl.ml = videoUrl.ml;
          }
        } else if (page.type === 2) {
          page.videoUrl = result.data.videoUrl;
          page.poster = result.data.videoCoverUrl;
        } else if (page.type === 5) {
          this.showHead = false;
          this.getPdfById(result.data.content)
          // this.getPdfById(50);
        } else {
          page.content = JSON.parse(result.data.content);
        }
        this.pageItem[this.pageKey] = page;
        
        this.conIt = true;
      } else {
        Dialog.alert({
          title: "提示",
          message: result.message,
        }).then(() => {
          // on close
        });
      }
    },
    onClickLeft() {
      this.$router.go(-1);
      // this.$parent.onChangeByRoute(this.formPath)
    },
    viewFile(url) {
      window.location.href = url;
    },
    async getPdfById(id) {
      let result = await this.$http.get(
        this.$baseURL.index.getPdfById() + "?id=" + id
      );
      result.videoCoverUrl = this.pageItem[this.pageKey].videoCoverUrl;
      this.padInfo = result;
    },
  },
  watch: {
    $route(to) {
      if (to.fullPath.indexOf("selfpage") !== -1) {
        this.pageKey = to.params.linkname;
        if (!this.pageItem[this.pageKey]) {
          this.conIt = false;
          this.getContent();
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
@themeColor: var(--themeColor);
/deep/ .van-skeleton__row,
.van-skeleton__title {
  background: #dedede;
}
p {
  margin: 0;
}
.page-bg {
  height: 100%;
  overflow-x: hidden;
  font-size: 14px;
  line-height: 22px;
  /deep/ p {
    margin: 0;
  }
  /deep/ img {
    // margin: 8px 0;
    max-width: 100% !important;
    height: auto;
  }
  /deep/ video {
    width: 100%;
  }
}
.file-container {
  // display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  padding: 0.3rem 0.3rem;
  overflow-y: auto;
  .file-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 3.2rem;
    width: calc(50% - 0.8rem);
    flex: 1;
    margin: 0.4rem;
    border-radius: 8px;
    background: @themeColor;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
    img {
      height: 0.9rem;
      width: 0.9rem;
    }
    span {
      display: inline-block;
      max-width: 100%;
      text-align: center;
      margin-top: 0.5rem;
      padding: 0 0.4rem;
      color: #fff;
      font-size: 0.3rem;
    }
  }
}
.video {
  height: 200px;
  object-fit: fill;
}
.container {
  min-height: 100%;
  .page-bg {
    background: none;
  }
}
</style>