<template>
  <div id="fullscreen">
    <div
      class="full-container"
      :style="[
        {
          background: `${
            padInfo.videoCoverUrl ? 'url(' + padInfo.videoCoverUrl + ')' : $store.state.themeInfo.themeColor
          }`
        }
      ]"
    >
      <div class="manual-wrap">
        <flipbook
          class="flipbook"
          :pages="data"
          :nPolygons="5"
          :singlePage="false"
          :startPage="page"
          :centering="true"
          :perspective="1000"
          :zooms="[1, 3]"
          ref="flipbook"
          :flipDuration="500"
          @flip-left-end="flipLeft"
          @flip-right-end="flipRight"
          @flip-right-start="flipRightStart"
          @flip-left-start="flipLeftStart"
        >
          <!-- 操作栏 -->
          <div class="action-bar">
            <div class="title">
              <div class="real-title">
                {{ title }}
              </div>
            </div>
            <div class="bar">
              <img :src="require('@/assets/images/ebook/list.png')" @click="isShowAside = !isShowAside" />
              <img
                :src="require(`@/assets/images/ebook/${isZoom ? 'small' : 'big'}.png`)"
                @click="zoomHandler"
              />
              <img
                :src="require(`@/assets/images/ebook/${isFullScreen ? 'out' : 'full'}.png`)"
                @click="fullScreen"
              />
              <img :src="require('@/assets/images/ebook/one.png')" @click="jumpPage(1)" />
              <img :src="require('@/assets/images/ebook/pre.png')" @click="prePage" />
              {{ page }} / {{ total }}
              <img :src="require('@/assets/images/ebook/next.png')" @click="nextPage" />
              <img :src="require('@/assets/images/ebook/last.png')" @click="jumpPage(total)" />
              <img :src="require('@/assets/images/ebook/down.png')" @click="downloadHandler" />
              <img
                :src="require(`@/assets/images/ebook/${!isAutoPlay ? 'play' : 'parse'}.png`)"
                @click="autoPlayHandler"
              />
            </div>
          </div>
        </flipbook>
      </div>
      <!-- 底部操作栏 -->
      <div class="footer-bar">
        <transition name="van-slide-up">
          <div class="page" v-show="isShowFooterPage">
            <img :src="require('@/assets/images/ebook/one.png')" @click="jumpPage(1)" />
            <img :src="require('@/assets/images/ebook/pre.png')" @click="prePage" />
            {{ page }} / {{ total }}
            <img :src="require('@/assets/images/ebook/next.png')" @click="nextPage" />
            <img :src="require('@/assets/images/ebook/last.png')" @click="jumpPage(total)" />
          </div>
        </transition>
        <div class="action">
          <img :src="require('@/assets/images/ebook/list.png')" @click="isShowAside = !isShowAside" />
          <img
            :src="require('@/assets/images/ebook/page.png')"
            @click="isShowFooterPage = !isShowFooterPage"
          />
          <img :src="require('@/assets/images/ebook/down.png')" @click="downloadHandler" />
          <img
            :src="require(`@/assets/images/ebook/${!isAutoPlay ? 'play' : 'parse'}.png`)"
            @click="autoPlayHandler"
          />
          <img :src="require(`@/assets/images/ebook/${isZoom ? 'small' : 'big'}.png`)" @click="zoomHandler" />
        </div>
      </div>
    </div>
    <!-- 侧边栏缩略图 -->
    <transition name="van-slide-left">
      <div class="aside" v-show="isShowAside">
        <!-- 关闭按钮 -->
        <div class="close" @click="isShowAside = false">
          <van-icon name="cross" />
        </div>
        <!-- 内容 -->
        <div class="content">
          <div v-for="(item, index) in data" class="item" :key="index" @click="jumpPage(index)">
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </transition>
    <!-- 左边上一页按钮 -->
    <div class="pre-btn fixed-btn" @click="prePage">
      <img :src="require('@/assets/images/ebook/pre.png')" />
    </div>
    <!-- 左边上一页按钮 -->
    <div class="next-btn fixed-btn" @click="nextPage">
      <img :src="require('@/assets/images/ebook/next.png')" />
    </div>
    <!-- 翻页音效 -->
    <TurnPageAudio ref="pageAudio" :isFull.sync="isFullScreen" />
  </div>
</template>
<script>
import Flipbook from "flipbook-vue";
import TurnPageAudio from "./turnPageAudio";
export default {
  name: "Reader",
  props: {
    padInfo: {
      type: Object
    }
  },
  components: {
    // turn,
    Flipbook,
    TurnPageAudio
  },
  data() {
    return {
      data: [null],
      bg: "",
      isShowAside: false,
      total: 0,
      page: 1,
      isAutoPlay: false,
      autoTime: null,
      title: "",
      filePath: "",
      isFullScreen: false,
      isZoom: false,
      isShowFooterPage: false
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      this.filePath = `${this.padInfo.address}${this.padInfo.filename}.${this.padInfo.type}`;
      this.title = this.padInfo.filename;
      this.total = this.padInfo.total;
      for (let index = 0; index < this.padInfo.total; index++) {
        this.data.push(`${this.padInfo.address}${index}.jpg`);
      }
    },
    // 下一页
    nextPage() {
      this.$refs.flipbook.flipRight();
      this.$refs.pageAudio.play();
      setTimeout(() => {
        this.page = this.$refs.flipbook.page;
        this.total = this.$refs.flipbook.numPages;
      }, 1100);
    },
    // 上一页
    prePage() {
      this.$refs.flipbook.flipLeft();
      this.$refs.pageAudio.play();
      setTimeout(() => {
        this.page = this.$refs.flipbook.page;
        this.total = this.$refs.flipbook.numPages;
      }, 1100);
    },
    // 向左翻页后触发
    flipLeft(page) {
      this.page = page;
    },
    // 向右翻页后触发
    flipRight(page) {
      this.page = page;
    },
    // 跳转到指定页数
    jumpPage(page) {
      this.page = page;
    },
    // 自动播放
    autoPlayHandler() {
      this.isAutoPlay = !this.isAutoPlay;
      if (!this.isAutoPlay) {
        clearInterval(this.autoTime);
        return;
      }
      this.autoTime = setInterval(() => {
        if (this.page >= this.total) {
          this.page = 1;
          return;
        }
        this.$refs.flipbook.flipRight();
      }, 4000);
    },
    // 下载pdf
    downloadHandler() {
      window.open(this.filePath, "_newTab");
    },
    // 全屏切换
    fullScreen() {
      this.$refs["pageAudio"].fullScreenToggle();
    },
    // 翻页开始时触发
    flipRightStart() {
      this.$refs.pageAudio.play();
    },
    flipLeftStart() {
      this.$refs.pageAudio.play();
    },
    // 放大缩小
    zoomHandler() {
      if (this.$refs.flipbook.canZoomIn) {
        this.isZoom = true;
        this.$refs.flipbook.zoomIn();
      } else {
        this.isZoom = false;
        this.$refs.flipbook.zoomOut();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .footer-bar {
    display: block !important;
    .page {
      position: fixed;
      bottom: calc(40px + env(safe-area-inset-bottom));
      width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: #036699 !important;
      color: white;
      font-size: 22px;
      img {
        width: 25px;
        height: 25px;
        margin: 5px;
      }
    }
    .action {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex !important;
      width: 100%;
      height: calc(40px);
      box-sizing: content-box;
      padding-bottom: env(safe-area-inset-bottom);
      font-size: 6px;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #036699 !important;
      color: #fff;
      font-size: 30px;
      img {
        width: 25px;
        height: 25px;
        margin: 10px;
      }
    }
  }
  .action-bar {
    .bar {
      height: 50px !important;
      background-color: blue;
      color: white;
      display: none !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      .van-icon {
        font-size: 16px;
        width: 30px;
      }
    }
    .title {
      display: flex !important;
      justify-content: center;
      background-color: #036699;
      color: white;
      padding: 10px 0;
      font-weight: 700;
      .real-title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .flipbook {
    /deep/ .viewport {
      height: calc(100vh - 80px) !important;
      padding: 5px;
      overflow: hidden;
    }
  }
  .pre-btn {
    display: none !important;
  }
  .next-btn {
    display: none !important;
  }
  .aside {
    width: 50vw !important;
  }
}
/*
pc
*/
.footer-bar {
  display: none;
}
.action-bar {
  .bar {
    height: 12px;
    background-color: #006699;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 5px;
    img {
      vertical-align: middle;
      width: 6px;
      height: 6px;
      margin: 0 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .title {
    display: none;
  }
}
.flipbook {
  width: 100%;
  height: 100%;
  /deep/ .viewport {
    margin: 0 auto;
    width: 93%;
    height: calc(100vh - 20px);
    padding: 5px;
    overflow: hidden;
  }
}
.full-container {
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  .manual-wrap {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity ease 0.5s;
    transition: opacity ease 0.5s;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
  }
  .manual-wrap.active {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.aside {
  width: 20vw;
  height: 100vh;
  background-color: #454545;
  position: fixed;
  color: white;
  left: 0;
  top: 0;
  z-index: 10000;
  .content {
    height: 96vh;
    overflow: scroll;
    margin-top: 4vh;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .close {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 3px;
    right: 3px;
  }
  .item {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    img {
      width: 70%;
      height: 100%;
    }
  }
}
.fixed-btn {
  position: fixed;
  top: 45%;
  padding: 10px 1px;
  background-color: #454545;
  &:hover {
    cursor: pointer;
  }
}
.pre-btn {
  left: 0;
}
.next-btn {
  right: 0;
}
</style>
